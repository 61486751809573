import { Link, useLocation } from 'react-router-dom';

import config, { Routes } from 'config/constants';
import useLang from 'hooks/useLang';

import './style.scss';

interface FooterLink {
  route: Routes;
}

const FooterLink = ({ route }: FooterLink) => {
  const { locale } = useLang();
  const location = useLocation();

  const isActive = location.pathname === route;
  const linkText = locale.routesMap[route];

  return isActive
    ? <b className="footer-link">{linkText}</b>
    : <Link className="footer-link" to={route}>{linkText}</Link>;
};

const FooterNav = () => (
  <ul className='footer-nav'>
    { config.footerNavRoutes.map((route, i) =>
      <li key={`footer-nav-${i}`}><FooterLink route={route} /></li>)
    }
  </ul>
);

export default FooterNav;
