import useLang from 'hooks/useLang';
import { Lang } from 'config/constants';

import './style.scss';

const LangMenu = () => {
  const { lang, langs, locale, switchLang } = useLang();
  return (
    <ul className="lang-menu">
      {langs.map((l, index) => (
        <li key={index}>
          {l !== lang &&  (
            <a onClick={() => switchLang(l as Lang)}>
              <span className="lang-menu-icon">{locale.langMap[l as Lang].icon}</span>
              {/* <span className="lang-menu-text">{locale.langMap[l as Lang].abbr}</span> */}
            </a>
          )}
        </li>
      ))}
    </ul>
  );
};

export default LangMenu;
