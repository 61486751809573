import BooksForm from 'components/AdminBooksForm';
import useLang from 'hooks/useLang';

import './style.scss';

const AboutPage = () => {
  const { locale } = useLang();
  return (
    <div className="admin-page">
      <h1>{locale.content.adminHeader}</h1>
      <div className="admin-page-content">
        <BooksForm />
      </div>
    </div>
  );
};
export default AboutPage;
