import { Lang } from 'config/constants';
import BooksList from 'components/BooksList';
import useLang from 'hooks/useLang';

import './style.scss';

const HomePage = () => {
  // const { locale } = useLang();
  const { lang, langs, locale, switchLang } = useLang();
  return (
    <div className={'main-page'}>
      <h1>{locale.content.mainHeader}</h1>
      <h2>
        <a onClick={() => switchLang(langs[0] as Lang)}>
          {locale.content.mainHeaderFrom} <span>🇺🇸</span>
        </a>
        <a onClick={() => switchLang(langs[1] as Lang)}>
        {locale.content.mainHeaderTo} <span>🇷🇺</span>
        </a>
      </h2>
      <BooksList />
    </div>
  );
};

export default HomePage;
