import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import MainRoute from 'routes';
import { BooksConfigProvider } from 'hooks/useBooks';
import { LangProvider } from 'hooks/useLang';

import 'App.scss';

const container = document.getElementById('root');
const root = container && createRoot(container);

root?.render(
  <React.StrictMode>
    <BrowserRouter>
      <LangProvider>
        <BooksConfigProvider>
          <MainRoute />
        </BooksConfigProvider>
      </LangProvider>
    </BrowserRouter>
  </React.StrictMode>
);
