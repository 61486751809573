import { useParams } from 'react-router-dom';

import BackButton from 'components/BackButton';
import BookCover from 'components/BookCover';
import useBooksConfig from 'hooks/useBooks';
import useLang from 'hooks/useLang';

import './style.scss';

const BookPage = () => {
  const { bookId } = useParams();
  const { lang, locale } = useLang();
  const { booksConfig } = useBooksConfig();

  if (booksConfig) {
    const book = booksConfig.books.find((b) => b.folder === bookId);
    const bookContent = book?.lang[lang];
    const title = bookContent ? bookContent.name : bookId;

    return (
      <div className={'book-page'}>
        <div className={'book-header'}>
          <BackButton />
          <h1>{title}</h1>
        </div>
        <div className={'book-content'}>
          <div className={'book-cover'}>
            <BookCover
              link={`/books/${book?.folder}`}
              cover={book?.cover}
              bookName={title}
              bookRelease={book?.created_at}
              bookType={locale.content.bookTranslationText}
            />
          </div>
          {
            bookContent?.annotation ? (
              <div className='book-annotation'>
                <h2>{locale.content.bookAnnotation}</h2>
                <p>{bookContent?.annotation}</p>
              </div>
            ): null
          }
          <div className={'book-chapters'}>
            <h2>{locale.content.bookChapters}</h2>
            <ul>
              {bookContent?.page.map((item: any, i: number) => (
                <li key={i}>
                  <a href={`/books/${book?.folder}/${item.file}`}>{item.name}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  } else {
    return null
  }
};

export default BookPage;
