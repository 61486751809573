import { useEffect, useState } from 'react';

import './style.scss';

interface MetaInfo {
  tag: string;
  commit: string;
  date: string;
}

const BuildInfo = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [metaInfo, setMetaInfo] = useState<MetaInfo | null>(null);

  useEffect(() => {
    const fetchMetaInfo = async () => {
      try {
        const response = await fetch('/tomsoir-build.json');
        const data = await response.json();
        setMetaInfo(data);
      } catch (error) {
        console.error('Error fetching meta info:', error);
      }
    };

    fetchMetaInfo();
  }, []);

  return (
    <div className={`build-info ${isOpen ? "open" : ""}`}>
        <i className='build-info-icon' onClick={() => setIsOpen(!isOpen) }>i</i>
        {metaInfo ? (
          <ul className='build-info-info'>
            <li><b>tag:</b> {metaInfo?.tag?.replace('refs/tags/', '')}</li>
            <li><b>updated:</b> {(new Date(metaInfo?.date)).toLocaleString()}</li>
            {/* <li>commit: {metaInfo!.commit}</li> */}
          </ul>
        ) : null}
    </div>
  );
};
export default BuildInfo;
