import { localStorageUserLang } from 'utils/localstorage';
import { createContext, useContext, useState } from 'react';
import config, { Lang, langs } from 'config/constants';
import { getLocale } from 'config/localization';

const _getLang = (): Lang => {
  const langInLS = localStorageUserLang.get<string>();
  const defaultLang = window.navigator.language;

  if (langInLS) {
    return langs.includes(langInLS) ? (langInLS as Lang) : config.defaultLang;
  } else {
    const newLang = langs.includes(defaultLang) ? (defaultLang as Lang) : config.defaultLang;
    localStorageUserLang.replace(newLang);
    return newLang;
  }
};

const _initialLang = _getLang();

const _useLang = () => {
  const [lang, setLang] = useState(_initialLang);
  const [locale, setLocale] = useState(getLocale(_initialLang));

  const switchLang = (lang: Lang) => {
    setLang(lang);
    setLocale(getLocale(lang));
    localStorageUserLang.replace(lang);
  };

  return { langs, lang, locale, switchLang };
};

const LangContext = createContext({
  langs,
  lang: _initialLang,
  locale: getLocale(_initialLang),
  switchLang: (_lang: Lang) => {
    return;
  },
});

export const LangProvider = ({ children }: { children: React.ReactElement }) => {
  const provider = _useLang();
  return <LangContext.Provider value={provider}>{children}</LangContext.Provider>;
};

const useLang = () => {
  return useContext(LangContext);
};

export default useLang;
