import { useEffect, useState } from 'react';

import useBooksConfig from 'hooks/useBooks';
import useLang from 'hooks/useLang';

import './style.scss';

const BooksUpdateForm = () => {
  const { locale } = useLang();
  const { booksConfigList, fetchBooksConfigList, addNewBooksConfig, activateBooksConfig, deleteBooksConfig, clearBooksConfig } = useBooksConfig();
  const [ selectedConfigIndex, setSelectedConfigIndex ] = useState<number | null>(null);
  const [ configUpdate, setConfigUpdate ] = useState<string | null>(null);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ isSortByLast, setIsSortByLast ] = useState<boolean>(false);

  // --------------------------------
  // Fetch for initial render
  // --------------------------------
  useEffect(() => {
    selectedConfigIndex == null ? fetchBooksConfigList() : null;
    isSortByLast ? selectLastCreatedConfig() : selectActiveConfig();
  }, [booksConfigList]);

  // --------------------------------
  // Utils
  // --------------------------------
  const selectActiveConfig = () => {
    const activeIndex = booksConfigList.findIndex((config) => config.active);
    (activeIndex || activeIndex === 0) && setSelectedConfigIndex(activeIndex);
  }
  const selectLastCreatedConfig = () => {
    setSelectedConfigIndex(0);
  }
  const getSelectedBooksConfig = () => {
    return selectedConfigIndex !== undefined
      ? booksConfigList[Number(selectedConfigIndex)]
      : null
  }
  const getBooksConfigUpdateAsText = () => {
    if (configUpdate) {
        const cfg = JSON.parse(configUpdate);
        const books = cfg.books;
        return JSON.stringify({books}, null, 4)
    }
    return null;
  }
  const applyNewConfigChangeToRefreshUI = async() => {
    setConfigUpdate(null);
    await fetchBooksConfigList();
  }

  // --------------------------------
  // Event handlers
  // --------------------------------
  const handleSelectConfigClick = (selectedIndex: number) => {
    setSelectedConfigIndex(selectedIndex);
    setConfigUpdate(null);
  }
  const handleConfigUpdateChange = (event: any) => {
    setConfigUpdate(event.target.value);
  };
  const handleClickSave = async () => {
    setIsLoading(true)
    setIsSortByLast(true);

    const booksConfigUpdate = getBooksConfigUpdateAsText();
    booksConfigUpdate && await addNewBooksConfig(booksConfigUpdate);

    // Refresh UI
    applyNewConfigChangeToRefreshUI();
    setIsLoading(false)
  }
  const handleClickActivate = async () => {
    setIsLoading(true)
    setIsSortByLast(false);

    const selectedBooksConfig = getSelectedBooksConfig();
    activateBooksConfig(selectedBooksConfig?.id)

    applyNewConfigChangeToRefreshUI()
    setIsLoading(false)
  }
  const handleClickDelete = async () => {
    setIsLoading(true)
    setIsSortByLast(false);

    const selectedBooksConfig = getSelectedBooksConfig();
    deleteBooksConfig(selectedBooksConfig?.id)

    applyNewConfigChangeToRefreshUI()
    setIsLoading(false)
  }

  const handleClickClear = async () => {
    setIsLoading(true)
    setIsSortByLast(false);

    const selectedBooksConfig = getSelectedBooksConfig();
    clearBooksConfig(selectedBooksConfig?.id)

    applyNewConfigChangeToRefreshUI()
    setIsLoading(false)
  }

  // --------------------------------
  // Button states
  // --------------------------------
  const selectedConfigText = selectedConfigIndex !== null
    ? JSON.stringify(booksConfigList[selectedConfigIndex], null, 4)
    : null

  const selectedTextareaValue = selectedConfigIndex !== null && !configUpdate
    ? selectedConfigText
    : configUpdate;

  const hasChangesInTextarea = Boolean(
    selectedConfigText &&
    selectedTextareaValue &&
    selectedConfigText !== selectedTextareaValue
  );

  const checkIsValidJSON = () => {
    try { return !!(selectedTextareaValue && JSON.parse(selectedTextareaValue)) }
    catch (error) { return false }
  }
  const isValidJSON = checkIsValidJSON();
  const isSelectedConfigActive = Boolean(isValidJSON && selectedTextareaValue && JSON.parse(selectedTextareaValue).active);
  const isSelectedConfigFrozen = Boolean(getSelectedBooksConfig()?.frozen);

  // --------------------------------
  // Render
  // --------------------------------
  return booksConfigList.length
    ? (
        <div className='book-update-form'>
          <ul className='book-update-date'>
            { booksConfigList.map((bookConfig, i) => (
              <li
                key={`cfg-${i}`}
                className={`book-config ${bookConfig.active ? 'active' : ''} ${i === selectedConfigIndex ? 'selected' : ''}`}
                onClick={ () => handleSelectConfigClick(i) }
              >
                {bookConfig.createdAt?.toLocaleDateString()}
                { ' ' }
                {bookConfig.createdAt?.toLocaleTimeString()}
              </li>
            )) }
          </ul>
          { selectedTextareaValue ? (
            <div className='book-update-config-wrap'>
              <textarea
                className={`book-update-config ${ isValidJSON ? '' : 'invalid-json' }`} value={selectedTextareaValue}
                onChange={handleConfigUpdateChange}
              />
              <div className='book-submits'>
                { isSelectedConfigFrozen ? null : [
                  <button
                    key={'clear'}
                    className='book-submit delete'
                    disabled={isLoading || isSelectedConfigActive || hasChangesInTextarea}
                    onClick={ handleClickClear }
                  >
                    🗑
                  </button>,
                  <button
                    key={'delete'}
                    className='book-submit delete'
                    disabled={isLoading || isSelectedConfigActive || hasChangesInTextarea}
                    onClick={ handleClickDelete }
                  >
                    {locale.content.submitDelete}
                  </button>
                ]}
                <button
                  className='book-submit activate'
                  disabled={isLoading || !isValidJSON || isSelectedConfigActive  || hasChangesInTextarea }
                  onClick={ handleClickActivate }
                >
                  {locale.content.submitActivate}
                </button>
                <button
                  className='book-submit save'
                  disabled={!hasChangesInTextarea || isLoading || !isValidJSON}
                  onClick={ handleClickSave }
                >
                  {locale.content.submitSave}
                </button>
              </div>
            </div>
          ) : null }
        </div>
      )
    : (
      <div className='book-update-form'>
        <div className='center'>{locale.content.adminNoData}</div>
      </div>
    );
}

export default BooksUpdateForm
