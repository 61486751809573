type LocalStorageValue = string | Record<string, any>;

enum LocalStorageKey {
  ColorTheme = 'theme',
  UserLang = 'lang',
  BooksConfig = 'books_config'
}

class LocalStorage {
  key: string;
  constructor(key: string) {
    this.key = key;
  }
  get<T>() {
    const state = window.localStorage.getItem(this.key);
    return state ? (JSON.parse(state) as T) : null;
  }
  add(value: LocalStorageValue) {
    return window.localStorage.setItem(this.key, JSON.stringify(value));
  }
  replace(value: LocalStorageValue) {
    this.remove();
    return window.localStorage.setItem(this.key, JSON.stringify(value));
  }
  remove() {
    return window.localStorage.removeItem(this.key);
  }
}

export const localStorageColorTheme = new LocalStorage(LocalStorageKey.ColorTheme);
export const localStorageUserLang = new LocalStorage(LocalStorageKey.UserLang);
export const localStorageBooksConfig = new LocalStorage(LocalStorageKey.BooksConfig);
