import { Lang, Routes } from 'config/constants';
import { Locale } from 'config/localization';

const EN: Locale = {
  langMap: {
    [Lang.Eng]: {
      name: 'english',
      abbr: 'to english',
      icon: '🇺🇸',
    },
    [Lang.Rus]: {
      name: 'russian',
      abbr: 'to russian',
      icon: '🇷🇺',
    },
  },
  routesMap: {
    [Routes.All]: 'All',
    [Routes.About]: 'About',
    [Routes.Admin]: 'Admin',
    [Routes.Home]: 'Books',
    [Routes.Book]: 'Book',
  },
  content: {
    aboutContacts: 'Contacts',
    aboutMeHeader: 'About',
    aboutMeIntro: "Hi, my name is Vladimir and I'm the professional book translator.",
    adminHeader: "Admin",
    adminNoData: "No data",
    bookChapters: 'Chapters',
    bookAnnotation: 'Annotation',
    bookHeader: 'About this book',
    bookRelease: 'release',
    bookTranslationDate: 'translation date',
    bookTranslationInProgress: 'translation in progress',
    bookTranslationText: 'translation',
    footerName: 'Vladimir Jirov',
    formConfig: 'Configuration',
    formUpdates: 'Updates',
    mainHeader: 'Translations',
    mainHeaderFrom: 'from English',
    mainHeaderTo: 'to Russian',
    submitActivate: 'Activate',
    submitDelete: 'Delete',
    submitSave: 'Save',
    themeButtonText: 'Switch to the different color theme:',
  },
};

export default EN;
