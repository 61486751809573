import { Link, useLocation } from 'react-router-dom';

import config, { Routes } from 'config/constants';
import LogoImageSrc from 'assets/images/logo.png';

import './style.scss';

const Logo = ({ to = config.defaultRoute }: { to?: Routes }) => {
  const { pathname } = useLocation();

  return pathname === '/' ? (
    <span className="logo">
      <img src={LogoImageSrc} />
    </span>
  ) : (
    <Link className="logo" to={to}>
      <img src={LogoImageSrc} />
    </Link>
  );
};

export default Logo;
