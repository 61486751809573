import { Lang, Routes } from 'config/constants';
import { Locale } from 'config/localization';

const RU: Locale = {
  langMap: {
    [Lang.Eng]: {
      name: 'английский',
      abbr: 'по-английски',
      icon: '🇺🇸',
    },
    [Lang.Rus]: {
      name: 'русский',
      abbr: 'по-русски',
      icon: '🇷🇺',
    },
  },
  routesMap: {
    [Routes.All]: 'Наше всё',
    [Routes.About]: 'О проекте',
    [Routes.Admin]: 'Админка',
    [Routes.Home]: 'Книги',
    [Routes.Book]: 'О книге',
  },
  content: {
    aboutContacts: 'Контакт',
    aboutMeHeader: 'О проекте',
    aboutMeIntro: 'Привет, меня зовут Владимир Жиров и я занимают профессиональным переводом книг.',
    adminHeader: "Админка",
    adminNoData: "Нет данных",
    bookChapters: 'Главы',
    bookAnnotation: 'Аннотация',
    bookHeader: 'О книге',
    bookRelease: 'Релиз',
    bookTranslationDate: 'Дата перевода',
    bookTranslationInProgress: 'перевод в процессе',
    bookTranslationText: 'перевод',
    footerName: 'Владимир Жиров',
    formConfig: 'Конфигурация',
    formUpdates: 'Обновления',
    mainHeader: 'Переводы',
    mainHeaderFrom: 'с английского',
    mainHeaderTo: 'на русский',
    submitActivate: 'Активировать',
    submitDelete: 'Удалить',
    submitSave: 'Сохранить',
    themeButtonText: 'Переключиться на цветовую тему:',
  },
};

export default RU;
