import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowBack } from 'assets/icons/arrow_back.svg';

import './style.scss';

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <button className="back-button" onClick={() => navigate('/')}>
      <ArrowBack />
    </button>
  );
};

export default BackButton;
