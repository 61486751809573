import FooterNav from 'components/FooterNav';
import BuildInfo from 'components/BuildInfo';
import Logo from 'components/Logo';
import ThemeButton from 'components/ThemeButton';
import LangMenu from 'components/LangMenu';
import useBooksConfig from 'hooks/useBooks';
import useLang from 'hooks/useLang';

import './style.scss';

interface LayoutProps {
  children: JSX.Element;
}

const Layout = ({ children }: LayoutProps) => {
  const { locale } = useLang();
  const { booksConfig } = useBooksConfig();

  return (
    <div className={`layout`}>
      <header className="layout-header">
        <Logo />
      </header>

      <main className="layout-main">{children}</main>

      <footer className="layout-footer">
        <BuildInfo />
        <ThemeButton />
        <LangMenu />
        <FooterNav />
        <span>
          © <i className='layout-footer-name'>{locale.content.footerName}</i> {new Date().getFullYear()}
        </span>
      </footer>
    </div>
  );
};

export default Layout;
