import { useEffect, useState, createContext, useContext, useCallback } from 'react';

import { BooksConfig, booksCollection } from 'app/firebase'
import { localStorageBooksConfig } from 'utils/localstorage';
import { arraysEqual } from 'utils/arries';

const getBooksConfigFromLS = (): BooksConfig | null => {
  const booksConfig = localStorageBooksConfig.get<BooksConfig>();
  if (booksConfig) {
    if (booksConfig?.createdAt) booksConfig.createdAt = new Date(booksConfig.createdAt);
    if (booksConfig?.updatedAt) booksConfig.updatedAt = new Date(booksConfig.updatedAt);
    if (booksConfig?.deletedAt) booksConfig.createdAt = new Date(booksConfig.deletedAt);
    return booksConfig;
  }
  return null;
}

const updateBooksConfigToLS = (booksConfig: BooksConfig): void => {
  const currentConfig = getBooksConfigFromLS();
  if (!currentConfig || currentConfig?.updatedAt !== booksConfig?.updatedAt) {
    localStorageBooksConfig.replace(booksConfig)
  }
}

const booksConfigFromLS = getBooksConfigFromLS()

const BooksConfigContext = createContext<{
  booksConfig: BooksConfig | null,
  isLoading: boolean,
  fetchBooksConfigList: () => Promise<BooksConfig[] | undefined> | null;
  booksConfigList: BooksConfig[];
  addNewBooksConfig: (newConfigValue: string) => void;
  activateBooksConfig: (docId?: string) => void;
  deleteBooksConfig: (docId?: string) => void;
  clearBooksConfig: (docId?: string) => void,
}>
({
  booksConfig: booksConfigFromLS,
  isLoading: Boolean(booksConfigFromLS?.books),
  fetchBooksConfigList: () => null,
  booksConfigList: [],
  addNewBooksConfig: () => null,
  activateBooksConfig: (docId?: string) => null,
  deleteBooksConfig: (docId?: string) => null,
  clearBooksConfig: (docId?: string) => null,
});

export const useBooksConfig = () => useContext(BooksConfigContext);

let isFetchedBookConfigOnceFromDB = false;
let isFetchedBookConfigListFromDB = false;
let isSavingNewBookConfigToDB = false;
let isActivatingBookConfigInDB = false;
let isDeletingBookConfigInDB = false;
let isClearingBookConfigInDB = false;

export const BooksConfigProvider = ({ children }:{ children: React.ReactElement }) => {
  const [booksConfig, setBooksConfig] = useState<BooksConfig | null>(booksConfigFromLS);
  const [isLoading, setIsLoading] = useState<boolean>(Boolean(booksConfigFromLS?.books));
  const [booksConfigList, setBooksConfigList] = useState<BooksConfig[]>(booksConfigFromLS ? [booksConfigFromLS]: []);

  // Fetch a single config on init
  const fetchBooksConfigOnceOnInit = useCallback(async () => {
    if (!isFetchedBookConfigOnceFromDB) {
      setIsLoading(isFetchedBookConfigOnceFromDB = true);
      try {
        const activeBooksConfig = await booksCollection.getSingleActiveBooksConfig();
        if (activeBooksConfig) {
          setBooksConfig(activeBooksConfig);
          updateBooksConfigToLS(activeBooksConfig);
        }
      } catch (error) {
        console.error('Failed to fetch books config on init:', error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [booksConfig, isLoading]);

  // Fetch a list of configs on Admin page open
  const fetchBooksConfigList = useCallback(async () => {
    try {
      if (!isFetchedBookConfigListFromDB) {
        isFetchedBookConfigListFromDB = true;

        // Refresh UI
        const newBooksConfigList = await booksCollection.getBooksConfigList()
        if (arraysEqual(booksConfigList, newBooksConfigList)){
          return booksConfigList;
        }
        await setBooksConfigList(newBooksConfigList);
        return newBooksConfigList;
      }
    } catch (error) {
      console.error('Failed to fetch books config list:', error);
    } finally {
      isFetchedBookConfigListFromDB = false;
    }
    return booksConfigList
  }, [booksConfigList]);

  // Save changes on Admin page
  const addNewBooksConfig = useCallback(async (newConfigValue: string) => {
    try {
      if (!isSavingNewBookConfigToDB) {
        isSavingNewBookConfigToDB = true;
        // Add
        await booksCollection.addNewBooksConfig(newConfigValue);
        // Refresh UI
        await fetchBooksConfigList()
      }
    } catch (error) {
      console.error('Failed to save new book config:', error);
    } finally {
      isSavingNewBookConfigToDB = false
    }
    return booksConfigList
  }, [booksConfigList]);

  // Activate config on Admin page
  const activateBooksConfig = useCallback(async (docId?: string) => {
    try {
      if (!isActivatingBookConfigInDB && docId) {
        isActivatingBookConfigInDB = true;
        // Deactivate all
        await booksCollection.deactivateCurrentBooksConfig()
        // Activate current
        await booksCollection.activateBooksConfig(docId);
        // Update LS
        const activeBooksConfig = await booksCollection.getSingleActiveBooksConfig();
        if (activeBooksConfig) {
          updateBooksConfigToLS(activeBooksConfig);
        }
        // Refresh UI
        await fetchBooksConfigList()
      }
    } catch (error) {
      console.error('Failed to activate new book config:', error);
    } finally {
      isActivatingBookConfigInDB = false
    }
    return booksConfigList
  }, [booksConfigList]);

  // Delete config change on Admin page
  const deleteBooksConfig = useCallback(async (docId?: string) => {
    try {
      if (!isDeletingBookConfigInDB && docId) {
        isDeletingBookConfigInDB = true;
        // Delete config
        await booksCollection.deleteBooksConfig(docId);
        // Refresh UI
        await fetchBooksConfigList()
      }
    } catch (error) {
      console.error('Failed to delete book config:', error);
    } finally {
      isDeletingBookConfigInDB = false
    }
  }, [booksConfigList]);

  // Clear config change on Admin page
  const clearBooksConfig = useCallback(async (docId?: string) => {
    try {
      if (!isClearingBookConfigInDB && docId) {
        isClearingBookConfigInDB = true;
        // Delete config
        await booksCollection.clearBooksConfig(docId);
        // Refresh UI
        await fetchBooksConfigList()
      }
    } catch (error) {
      console.error('Failed to delete book config:', error);
    } finally {
      isClearingBookConfigInDB = false
    }
  }, [booksConfigList]);


  useEffect(() => {
    fetchBooksConfigOnceOnInit();
  }, [fetchBooksConfigOnceOnInit]);

  return (
    <BooksConfigContext.Provider value={{
      booksConfig,
      isLoading,
      fetchBooksConfigList,
      booksConfigList,
      addNewBooksConfig,
      activateBooksConfig,
      deleteBooksConfig,
      clearBooksConfig,
    }}>
      {children}
    </BooksConfigContext.Provider>
  );
};

export default useBooksConfig;
