import BookCover from 'components/BookCover';
import useBooksConfig from 'hooks/useBooks';
import useLang from 'hooks/useLang';

import './style.scss';

const BooksList = () => {
  const { lang, locale } = useLang();
  const { booksConfig } = useBooksConfig();

  return booksConfig ? (
    <ul className="books">
      {booksConfig.books.map((book) => (
        <li key={book.folder}>
          <div className="books-cover">
            <BookCover
              link={`/books/${book?.folder}`}
              bookName={book.lang[lang].name}
              bookType={locale.content.bookTranslationText}
              bookRelease={book.created_at}
              cover={book.cover}
            />
          </div>
          <div className="books-description">
            <ol>
              <li>
                <h2 className="books-description-header">{book.lang[lang].name}</h2>
              </li>
              <li>
                <h3 className="books-description-author">{book.lang[lang].origin_author}</h3>
              </li>
              {/* <li>
                <h3 className="books-description-date">
                  {`${new Date(book.created_at).getFullYear()} / ${new Date(book.created_at).getMonth()}`}
                </h3>
              </li> */}
            </ol>
          </div>
        </li>
      ))}

      <li key={'in_progress'} className={'book-in-progress'}>
        <BookCover bookType={locale.content.bookTranslationText} isInProgress />
      </li>
    </ul>
  ): null;
};

export default BooksList;
