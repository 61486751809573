import { localStorageColorTheme } from 'utils/localstorage';
import { useEffect, useState } from 'react';

export enum ThemeMode {
  Light = 'light',
  Dark = 'dark',
}

const getTheme = (): ThemeMode => {
  const defaultTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const theme = localStorageColorTheme.get();

  if (theme) {
    return theme === ThemeMode.Dark ? ThemeMode.Dark : ThemeMode.Light;
  } else {
    const newTheme = defaultTheme ? ThemeMode.Dark : ThemeMode.Light;
    localStorageColorTheme.replace(newTheme);
    return newTheme;
  }
};

const initialTheme = getTheme();

const useTheme = () => {
  const [theme, setTheme] = useState(initialTheme);

  const switchTheme = (theme: ThemeMode) => {
    setTheme(theme);
    localStorageColorTheme.replace(theme);
  };

  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  return { theme, switchTheme };
};

export default useTheme;
