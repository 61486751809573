import EN from './dicts/en';
import RU from './dicts/ru';

import { Lang, Routes } from 'config/constants';

export interface Locale {
  langMap: {
    [key in Lang]: {
      name: string;
      abbr: string;
      icon: string;
    };
  };
  routesMap: { [key in Routes]: string };
  content: {
    aboutContacts: string;
    aboutMeHeader: string;
    aboutMeIntro: string;
    adminHeader: string;
    adminNoData: string;
    bookAnnotation: string;
    bookChapters: string;
    bookHeader: string;
    bookRelease: string;
    bookTranslationDate: string;
    bookTranslationInProgress: string;
    bookTranslationText: string;
    footerName: string;
    formConfig: string;
    formUpdates: string;
    mainHeader: string;
    mainHeaderFrom: string;
    mainHeaderTo: string;
    submitActivate: string;
    submitDelete: string;
    submitSave: string;
    themeButtonText: string;
  };
}

export interface LocalePageMap {
  title: string;
  intro: string;
}

export type I18N = { [key in Lang]: Locale };

export const getLocale = (lang: Lang): Locale => {
  return i18n[lang];
};

const i18n: I18N = {
  [Lang.Eng]: EN,
  [Lang.Rus]: RU,
};
