import { Link } from 'react-router-dom';

import useLang from 'hooks/useLang';

import './style.scss';

interface Props {
  link?: string;
  bookName?: string;
  bookType?: string;
  bookRelease?: string;
  isInProgress?: boolean;
  cover?: string | null;
}

const getRelease = (date?: string) =>
  date ? `${new Date(date).getMonth()}/${new Date(date).getFullYear()}` : new Date().getFullYear();

const Content = ({ isInProgress, bookName, bookType, bookRelease }: Props) => {
  const { locale } = useLang();
  const releaseDate = getRelease(bookRelease);
  return isInProgress ? (
    <>
      <b className="inProgress">{locale.content.bookTranslationInProgress}</b>
      <span>{locale.content.bookRelease}</span>
      <span>{releaseDate}</span>
    </>
  ) : (
    <>
      <b>{bookName}</b>
      <span>{bookType}</span>
      <span>{releaseDate}</span>
    </>
  );
};

const BookCover = (props: Props) => {
  return (
    <div className={props.isInProgress ? 'cover in-progress' : 'cover'} style={ props.cover ? {backgroundImage: `url(${props.link}/${props.cover})`} : {}}>
      {props.link ? (
        <Link to={props.link}>
          { !props.cover && <Content {...props} />}
        </Link>
      ) : (
        <i>
          <Content {...props} />
        </i>
      )}
    </div>
  );
};

export default BookCover;
