import useTheme, { ThemeMode } from 'hooks/useTheme';
import useLang from 'hooks/useLang';
import { ReactComponent as SunIcon } from 'assets/icons/sun.svg';
import { ReactComponent as MoonIcon } from 'assets/icons/moon.svg';

import './style.scss';

const ThemeButton = () => {
  const { locale } = useLang();
  const { theme, switchTheme } = useTheme();
  const oppositeTheme = theme === ThemeMode.Light ? ThemeMode.Dark : ThemeMode.Light;

  return (
    <button
      className="theme-button"
      title={`${locale.content.themeButtonText}: ${oppositeTheme}`}
      onClick={() => switchTheme(oppositeTheme)}
    >
      {theme === ThemeMode.Light ? <MoonIcon /> : <SunIcon />}
    </button>
  );
};

export default ThemeButton;
