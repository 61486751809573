import { Link } from 'react-router-dom';

import config from 'config/constants';

import './style.scss';

export enum Types {
  NotFound = '404',
  InternalError = '500',
}

const ErrorPage = ({ type }: { type: Types }) => {
  return (
    <div className="error">
      <h1 className="error-title">{type}</h1>
      <Link className="error-root" to={config.defaultRoute}>
        back to home page
      </Link>
    </div>
  );
};

export default ErrorPage;
