import { FirebaseApp, initializeApp } from 'firebase/app';
import { Analytics as FirebaseAnalytics, getAnalytics } from 'firebase/analytics';
import {
  addDoc,
  collection,
  doc,
  DocumentData,
  DocumentReference,
  deleteDoc,
  getDoc,
  getDocs,
  getFirestore,
  Firestore,
  limit,
  orderBy,
  query,
  Timestamp,
  updateDoc,
  where,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
import { firebaseConfig } from 'config/firebase';

// --------------------------------
// DB record structure
// --------------------------------
// Incoming from DB records
interface BooksConfigDBRecord {
  id: string;
  config: string;     // stringified JSON
  active: boolean;
  frozen: boolean;
  created_at: {       // Firestore date formate in each doc
    nanoseconds: number;
    seconds: number;
  }
  updated_at: {       // Firestore date formate in each doc
    nanoseconds: number;
    seconds: number;
  }
  deleted_at: {       // Firestore date formate in each doc
    nanoseconds: number;
    seconds: number;
  }
}
// Data to store as new DB record
interface BooksConfigNewRecordData {
  config: string;     // stringified JSON
  active: boolean;
  frozen: boolean;
  created_at: Timestamp,
  updated_at?: Timestamp,
  deleted_at?: Timestamp,
}

// --------------------------------
// Normalized config field
// --------------------------------
interface BookConfigPage {
  name: string;
  file: string;
}
interface BookConfigLang {
  name: string;
  origin_author: string;
  annotation: string;
  page: BookConfigPage[];
}
interface BookConfig {
  folder: string;
  cover: string;
  created_at: string;
  lang: {
      en: BookConfigLang;
      ru: BookConfigLang;
  };
}

// --------------------------------
// DB record normalized model
// --------------------------------
export interface BooksConfig {
  id?: string;
  books: BookConfig[];
  active: boolean;
  frozen: boolean;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

//
// Base class
//
class FirebaseBase {
  app: FirebaseApp;
  analytics: FirebaseAnalytics;
  db: Firestore;

  constructor() {
    const { app, analytics, db } = this.initialize();

    this.app = app;
    this.analytics = analytics;
    this.db = db;
  }

  initialize() {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(this.app);
    const db = getFirestore(this.app);
    return { app, analytics, db };
  }
}

//
// Books collection
//
class BooksCollection extends FirebaseBase {
  collectionId = 'vladbooksProject/booksConfig/updates';

  // Utils
  _getDataFromDocSnapshot = (doc: QueryDocumentSnapshot): BooksConfigDBRecord => {
    return ({id: doc.id, ...doc.data() }) as BooksConfigDBRecord
  }
  _getDataByRef = async (docRef: DocumentReference) => {
    const docSnapshot = await getDoc(docRef);
    if (!docSnapshot.exists()) return undefined;
    const data = this._getDataFromDocSnapshot(docSnapshot);
    return this._normalizeDBRecord(data);
  }
  _getDateFromDB = ({nanoseconds, seconds}: {nanoseconds: number, seconds: number}):Date => {
    const nano = String(nanoseconds.toString().slice(0, 3));
    const secs = String(seconds);
    return new Date(Number(secs+nano));
  }
  _normalizeDBRecord(record?: BooksConfigDBRecord): undefined | BooksConfig {
    if (!(record && record?.config && record?.created_at))
      return undefined;

    const books = JSON.parse(record.config)?.books;
    if (!books) return undefined;

    const createdAt = this._getDateFromDB(record.created_at);
    const updatedAt = record.updated_at && this._getDateFromDB(record.updated_at);
    const deletedAt = record.deleted_at && this._getDateFromDB(record.deleted_at);

    const active = Boolean(record.active);
    const frozen = Boolean(record.frozen);
    const id = record.id;

    return { id, active, frozen, books, createdAt, updatedAt, deletedAt };
  }

  // Common methods
  private getDocById = async (docId: string): Promise<BooksConfig | undefined> => {
    const docRef = doc(this.db, this.collectionId, docId);
    const data = await this._getDataByRef(docRef)
    return data;
  }
  private addNewDoc = async (data: BooksConfigNewRecordData): Promise<BooksConfig | undefined> => {
    const projectCollection = collection(this.db, this.collectionId)
    const newDocRef = await addDoc(projectCollection, data);
    const newData = this._getDataByRef(newDocRef);
    return newData;
  }
  private updateDocById = async (docId: string, updatedData: BooksConfigNewRecordData): Promise<BooksConfig | undefined> => {
    const docRef = doc(this.db, this.collectionId, docId);
    await updateDoc(docRef, updatedData as DocumentData);
    const data = await this._getDataByRef(docRef)
    return data;
  }
  private deleteDocById = async (docId: string): Promise<void> => {
    const docRef = doc(this.db, this.collectionId, docId);
    await deleteDoc(docRef);
  }

  // Books config API methods
  getSingleActiveBooksConfig = async (): Promise<BooksConfig | undefined> => {
    const projectCollection = collection(this.db, this.collectionId);
    const projectQuery = query(projectCollection, where("active", "==", true));
    const projectSnapshot = await getDocs(projectQuery);
    const projectRecordsList = projectSnapshot.docs.map(this._getDataFromDocSnapshot);
    const projectRecordsListLFiltered = projectRecordsList.filter(projectRecord => !projectRecord.deleted_at)
    const projectRecord = projectRecordsListLFiltered[0];
    return this._normalizeDBRecord(projectRecord);
  }

  getBooksConfigList = async (recs_limit = 1000): Promise<BooksConfig[]> => {
    const projectCollection = collection(this.db, this.collectionId);
    const projectQuery = query(projectCollection, orderBy("created_at", "desc"), limit(recs_limit));
    const projectSnapshot = await getDocs(projectQuery);

    const projectRecordsList = projectSnapshot.docs.map(this._getDataFromDocSnapshot);
    const configs = projectRecordsList.map(projectRecord => this._normalizeDBRecord(projectRecord));
    const configsFiltered = configs.filter(config => config && !config.deletedAt) as BooksConfig[];
    return configsFiltered;
  }

  addNewBooksConfig = async (config = ""): Promise<BooksConfig | undefined> => {
    const newDate = this.addNewDoc({
      config,
      frozen: false,
      active: false,                // Not active by default
      created_at: Timestamp.now(),  // Now timestamp
    });
    return newDate;
  }

  deactivateCurrentBooksConfig = async (): Promise<void> => {
    try {
      const projectCollection = collection(this.db, this.collectionId)
      const projectQuery = query(projectCollection, where("active", "==", true));
      const querySnapshot = await getDocs(projectQuery); // Execute the query
      querySnapshot.forEach(async (doc) => {
        const docRef = doc.ref;
        await updateDoc(docRef, {active: false});
        console.log(`Document with ID ${doc.id} updated`);
      });
    } catch (e) {
      console.error("Error updating document: ", e);
    }
  }

  activateBooksConfig = async (docId: string): Promise<void> => {
    try {
      this.updateDocById(docId, {active: true} as BooksConfigNewRecordData);
    } catch (e) {
      console.error("Error updating document: ", e);
    }
  }

  deleteBooksConfig = async (docId: string): Promise<void> => {
    try {
      const data = await this.getDocById(docId);
      if (data && data.active == false) {
        this.updateDocById(docId, {
          active: false,
          deleted_at: Timestamp.now(),
        } as BooksConfigNewRecordData);
      } else {
        console.warn(`Activated doc: ${docId}`);
      }
    } catch (e) {
      console.error("Error updating document: ", e);
    }
  }

  clearBooksConfig = async (docId: string): Promise<void> => {
    try {
      const data = await this.getDocById(docId);
      if (data && data.active == false) {
        this.deleteDocById(docId)
      } else {
        console.warn(`Activated doc: ${docId}`);
      }
    } catch (e) {
      console.error("Error updating document: ", e);
    }
  }
}

export const booksCollection = new BooksCollection();
